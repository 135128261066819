import React, {useEffect, useState} from "react"
import PropTypes from "prop-types"
import CardVideoPlayer from "./CardVideoPlayer"
import AudioRecording from "./AudioRecording"
import NextButton from "./NextButton"
import pronunciationCardResponseApi from "../api/pronunciationCardResponses"

const PronunciationExercise = ({ card, isLastCard, moveToNextCard, deckPlayThroughId, cardResponse }) => {
  const [displayNextButton, setDisplayNextButton] = useState(false)
  const [isVideoPlaying, setVideoPlaying] = useState(false);
  const [isAudioActive, setAudioIsActive] = useState(false);
  const [date, setDate] = useState(null);

  const nextClicked = async () => {
    await pronunciationCardResponseApi.complete(cardResponse.id, deckPlayThroughId)
    moveToNextCard()
    if (!isLastCard) {
      setDisplayNextButton(false)
    }
  }

  const updateWatchedCount = (watchCount) => {
    pronunciationCardResponseApi.update(cardResponse.id, deckPlayThroughId, watchCount)
  }

  useEffect(() => {
      if (card && card.videoUrl) {
          setDate(Date.now())
      }
  }, [card.videoUrl])

  return (
    <div className="pronunciation-exercises">
      <h1 className="text-center">{card.title}</h1>
        {date &&
            <CardVideoPlayer
                enabled={!isAudioActive}
                handleVideoPlaying={(isPlaying) => setVideoPlaying(isPlaying)}
                url={`${card.videoUrl}?v=${date}`}
                poster={card.posterUrl}
                onWatchCountUpdate={updateWatchedCount}
            />
        }
      <AudioRecording
        enabled={!isVideoPlaying}
        handleAudioIsActive={(isActive) => setAudioIsActive(isActive)}
        cardResponseId={cardResponse.id}
        onRecording={() => setDisplayNextButton(false) }
        onRecordingCompleted={() => setDisplayNextButton(true)}
      />
      <NextButton visible={displayNextButton} finish={isLastCard} onClick={nextClicked} />
    </div>
  )
}

PronunciationExercise.propTypes = {
  card: PropTypes.object.isRequired,
  isLastCard: PropTypes.bool.isRequired,
  moveToNextCard: PropTypes.func.isRequired,
  deckPlayThroughId: PropTypes.number.isRequired,
  cardResponse: PropTypes.object.isRequired,
};

export default PronunciationExercise;
