import React, {useState, useEffect, useCallback, useRef} from "react"
import PropTypes from "prop-types"
import getBlobDuration from 'get-blob-duration'
import Button from "./Button"
import { createCardResponseAudio } from "../api/audioRecordings"
import { logDetailedMetrics, logMicPermissionIssue } from "../app/detailed_rollbar_log";

let recorder

const AudioState = {
  NOT_READY: "notready", // requesting access to the microphone
  READY: "ready", // ready to record
  RECORDING: "recording",
  SUBMITTING: "submitting",
  PLAYING: "playing", // playback is happening, record should be disabled
  ERROR: "error",
}

const AudioRecording = ({ onRecording, onRecordingCompleted, cardResponseId, handleAudioIsActive, enabled = true }) => {
  const [audioBlob, setAudioBlob] = useState(null)
  const [stream, setStream] = useState(null)
  const [audio, setAudio] = useState(null)
  const [audioState, setAudioState] = useState(AudioState.NOT_READY)
  const cardIdRef = useRef(cardResponseId);

  const initializeAudio = (blob) => {
    if (audio) {
      audio.pause();
      audio.src = "";
      audio.load();
    }
    const newAudio = new Audio(window.URL.createObjectURL(blob));
    setAudio(newAudio);
    return newAudio;
  }

  const submitRecording = useCallback(async (e) => {
    const duration = await getBlobDuration(e.data)
    const audio = initializeAudio(e.data)
    const cardIdCurrent = cardIdRef.current;
    setAudioBlob(e.data)
    setAudio(audio)
    createCardResponseAudio(cardIdCurrent, e.data, duration).then(() => {
      setAudioState(AudioState.READY)
      if (onRecordingCompleted) onRecordingCompleted()
    }).catch((reason) => {
      Rollbar.error('Error when uploading audio recording.', reason);
      alert("There was an error uploading the audio file")
      setAudioState(AudioState.ERROR)
      if (onRecordingCompleted) onRecordingCompleted()
    })

  }, [])

  const stopStreamTracks = () => {
    if (stream) {
      stream.getTracks().forEach(track => {
        track.stop();
        stream.removeTrack(track);
      });
    }
  };

  useEffect(() => {
    cardIdRef.current = cardResponseId;
  }, [cardResponseId]);

  useEffect(() => {
    if (audioBlob) {
      initializeAudio(audioBlob);
    }
  }, [audioBlob]);

  const getAudioStream = async () => {
    setAudioState(AudioState.NOT_READY)
    let streamToSave;
    try {
      // This will request audio permissions the first time it's called
      streamToSave = await navigator.mediaDevices.getUserMedia({ audio: true })
      setStream(streamToSave)
      setAudioState(AudioState.READY)
    } catch(err) {
      logMicPermissionIssue(err);
      logDetailedMetrics();
      setAudioState(AudioState.ERROR)
    }
    return streamToSave;
  }

  const recordAudio = async () => {
    const streamToRecord = await getAudioStream().catch(err => {
      Rollbar.error('Error when fetching an audio stream.', err);
      alert(err.toString())
    });

    if (streamToRecord) {
      recorder = new window.MediaRecorder(streamToRecord)
      // This method is called after the recording is stopped
      recorder.addEventListener('dataavailable', submitRecording)
      recorder.start()
      setAudioState(AudioState.RECORDING)
      if (onRecording) onRecording()
    }
  }

  const stopRecording = () => {
    recorder.stop()
    setAudioState(AudioState.SUBMITTING)
    stopStreamTracks();
  }

  const abortRecording = () => {
    recorder.removeEventListener('dataavailable', submitRecording)
    recorder.stop()
    setAudioState(AudioState.READY)
    stopStreamTracks();
  }

  const playbackAudio = () => {
    if (audio && audioState !== AudioState.PLAYING && audioState !== AudioState.RECORDING) {
      stopStreamTracks();
      setAudioState(AudioState.PLAYING);
      audio.volume = 1.0;
      audio.addEventListener("ended", handleAudioStopped);
      audio.play(0);
    }
  }

  const abortAudioPlayback = () => {
    audio.currentTime = 0
    audio.pause();
    handleAudioStopped();
  }

  const handleAudioStopped = () => {
    if (audio) {
      audio.removeEventListener("ended", handleAudioStopped);
    }
    setAudioState(AudioState.READY);
  }

  useEffect(() => {
    setAudioBlob(null)
  }, [cardResponseId]);

  // Request permissions on component mount to avoid delay when clicking recording button
  useEffect(() => {
    getAudioStream().catch(err => {
      Rollbar.error('Error when fetching microphone permissions on initial mount.', err);
      alert(err.toString())}
    );
  }, [])

  useEffect(() => {
    if(!enabled) {
      if(audioState === AudioState.RECORDING) {
        abortRecording();
      }
      else if(audioState === AudioState.PLAYING) {
        abortAudioPlayback();
      }
    }
  }, [enabled])

  useEffect(() => {
    if(handleAudioIsActive) {
      if ([AudioState.PLAYING, AudioState.RECORDING].includes(audioState)) {
        handleAudioIsActive(true);
      } else {
        handleAudioIsActive(false);
      }
    }
  }, [audioState])

  const buttonVariant = audioBlob ? "outline-primary audioRecordingSecondaryButton" : "primary"
  return (
    <div className="row mt-2">
      <div className={`${audioBlob ? "col-6 pe-1" : "col-12"}`}>
        {stream
          ?
            <Button
              text={audioBlob ? "Record Again" : "Record"}
              icon="microphone"
              onClick={recordAudio}
              visible={audioState !== AudioState.RECORDING && audioState !== AudioState.SUBMITTING }
              variant={buttonVariant}
              disabled={audioState === AudioState.PLAYING || !enabled}
            />
          :
            <Button
              disabled={!enabled}
              text={"Configuring microphone..."}
              icon="microphone"
              onClick={getAudioStream}
              visible={true}
            />
        }
        <Button
          disabled={!enabled}
          text="Submitting..."
          iconPrefix="fas"
          icon="spinner fa-pulse"
          visible={audioState === AudioState.SUBMITTING}
          variant={buttonVariant}
        />
        <Button
          disabled={!enabled}
          text="Stop Recording"
          iconPrefix="fas"
          icon="circle text-danger"
          onClick={stopRecording}
          visible={audioState === AudioState.RECORDING}
          variant={buttonVariant}
        />
      </div>
      {audioBlob && (
        <div className="col-6 ps-1">
          <Button
            variant={buttonVariant}
            earIconBlue={true}
            text={audioState === AudioState.PLAYING ? "Listening..." : "Listen"}
            onClick={audioState === AudioState.PLAYING ? abortAudioPlayback : playbackAudio}
            disabled={audioState === AudioState.RECORDING || !enabled}
          />
        </div>
      )}
      {audioState === AudioState.ERROR &&
        <div className="mt-3 col-12 alert alert-danger">
          Error: Unable to access the microphone.
          {process.env.RAILS_ENV === 'development' && window.location.protocol !== 'https:' && <span><br/>
            In some browsers, microphone access request is only shown
            when using a secured connection (https) or when using 127.0.0.1.
          </span>}
        </div>
      }
    </div>
  )
}

AudioRecording.propTypes = {
  enabled: PropTypes.bool,
  handleAudioIsActive: PropTypes.func,
  cardResponseId: PropTypes.number,
  onRecording: PropTypes.func,
  onRecordingCompleted: PropTypes.func,
}

export default AudioRecording
