import {StarRating} from '../vendor/star-rating.js';
function setup() {
    document.querySelectorAll('.btn-outline').forEach(button => {
        button.addEventListener('touchstart', function (event) {
            event.preventDefault();
            this.classList.add('btn-outline-focus');
        });
        button.addEventListener('touchend', function (event) {
            this.classList.remove('btn-outline-focus');
            addHiddenFieldAndSubmit(event, button);
        });

        button.addEventListener('touchcancel', function () {
            this.classList.remove('btn-outline-focus');
        });
    });


    const star_rating = new StarRating('.star-rating');

}

function addHiddenFieldAndSubmit(event, button) {
    const form = event.target.closest('form');
    const hiddenField = document.createElement('input');
    hiddenField.type = 'hidden';
    hiddenField.name = 'commit';
    hiddenField.value = button.value;
    form.appendChild(hiddenField);
    form.submit();
}

document.addEventListener("modal.load", setup);