import React, {useState, useEffect, useRef} from "react"
import PropTypes from "prop-types"
import {
  Player,
  BigPlayButton,
  ControlBar,
} from "video-react"
import { logDetailedMetrics, logMicPermissionIssue } from "../app/detailed_rollbar_log";

const CardVideoPlayer = ({ poster, url, onWatchCountUpdate, handleVideoPlaying, enabled = true }) => {
  const [videoWatchedCount, setVideoWatchedCount] = useState(0)
  const [currentPlayTracked, setCurrentPlayTracked] = useState(false)
  const videoPlayerRef = useRef();

  const onPlay = () => {
    stopOtherAudioStreams().catch(err => {
      Rollbar.error('Error stopping other audio streams', err);
      alert(err.toString())
    });

    if (!currentPlayTracked) {
      if(handleVideoPlaying) handleVideoPlaying(true);
      setCurrentPlayTracked(true)
      const updatedWatchedCount = videoWatchedCount + 1
      setVideoWatchedCount(updatedWatchedCount)
      onWatchCountUpdate(updatedWatchedCount)
    }
  }

  const abortPlayback = () => {
    videoPlayerRef.current.pause();
    videoPlayerRef.current.seek(0);
    setCurrentPlayTracked(false);
  }

  const stopOtherAudioStreams = async () => {
      try {
        // This will request audio permissions the first time it's called
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true })
        stream.getTracks().forEach( track => {
          track.stop(); stream.removeTrack(track);
        });
      } catch(err) {
        logMicPermissionIssue(err);
        logDetailedMetrics();
      }
  }

  const onEnded = () => {
    if(handleVideoPlaying) handleVideoPlaying(false);
    setCurrentPlayTracked(false)
  }

  useEffect(() => {
    setVideoWatchedCount(0)
  }, [url]);

  useEffect(() => {
    if(!enabled) {
      abortPlayback()
    }
  }, [enabled])

  useEffect(() => {
    if(handleVideoPlaying) {
      handleVideoPlaying(currentPlayTracked);
    }
  }, [currentPlayTracked])
  return (
    <div className={enabled ? 'hidden' : 'disable-clicks'}>
      <Player ref={videoPlayerRef} poster={poster} className="cardVideoPlayer"
        onPlay={onPlay} onError={onEnded} onPause={onEnded} onEnded={onEnded}>
        <source src={url}/>
        <BigPlayButton position="center" />
        <ControlBar disabled={true} />
      </Player>
    </div>
  )
}

CardVideoPlayer.propTypes = {
  enabled: PropTypes.bool,
  handleVideoPlaying: PropTypes.func,
  url: PropTypes.string.isRequired,
  poster: PropTypes.string,
  onWatchCountUpdate: PropTypes.func.isRequired
};

export default CardVideoPlayer
