export const logDetailedMetrics = async () => {
    const metrics = {};

    if (navigator.storage && navigator.storage.estimate) {
        try {
            const { usage, quota } = await navigator.storage.estimate();
            metrics.storage = { usage, quota };
        } catch (error) {
            metrics.storageError = { message: error.message, stack: error.stack };
        }
    }

    let localStorageSize = 0;
    for (const key in localStorage) {
        if (localStorage.hasOwnProperty(key)) {
            const itemSize = (localStorage.getItem(key) || "").length;
            localStorageSize += key.length + itemSize;
        }
    }
    metrics.localStorage = {
        keys: localStorage.length,
        size: localStorageSize,
    };

    const cookies = document.cookie.split("; ");
    const totalSize = cookies.reduce((sum, cookie) => sum + cookie.length, 0);
    metrics.cookies = { totalSize, count: cookies.length };

    if (performance && performance.memory) {
        const memoryInfo = {
            usedJSHeapSize: performance.memory.usedJSHeapSize,
            totalJSHeapSize: performance.memory.totalJSHeapSize,
            jsHeapSizeLimit: performance.memory.jsHeapSizeLimit,
        };
        metrics.memory = memoryInfo;

        if (memoryInfo.usedJSHeapSize / memoryInfo.jsHeapSizeLimit > 0.8) {
            metrics.highMemoryUsage = true;
        }
    }

    Rollbar.info("Detailed Metrics", metrics);
};

export const logMicPermissionIssue = async (error) => {
    const detectOS = () => {
        const userAgent = navigator.userAgent || "";
        if (/Windows/i.test(userAgent)) return "Windows";
        if (/Mac/i.test(userAgent)) return "MacOS";
        if (/Linux/i.test(userAgent)) return "Linux";
        if (/Android/i.test(userAgent)) return "Android";
        if (/iPhone|iPad|iPod/i.test(userAgent)) return "iOS";
        return "Unknown";
    };

    let micPermission = "Not supported";
    if (navigator.permissions) {
        try {
            const permissionStatus = await navigator.permissions.query({name: "microphone"});
            micPermission = permissionStatus.state;
        } catch (err) {
            micPermission = `Permission check failed: ${err.message}`;
        }
    }

    const navigatorInfo = {
        userAgent: navigator.userAgent,
        userAgentData: navigator.userAgentData
            ? {
                platform: navigator.userAgentData.platform || "Unknown",
                brands: navigator.userAgentData.brands || "Unknown",
                mobile: navigator.userAgentData.mobile || false,
            }
            : null,
        language: navigator.language,
        hardwareConcurrency: navigator.hardwareConcurrency || "Unknown",
        os: detectOS(),
    };

    const detailedErrorInfo = {
        errorMessage: error.message,
        errorName: error.name,
        errorStack: error.stack || "No stack available",
        navigatorInfo,
        permissions: {
            mic: micPermission,
        },
    };
    Rollbar.error("Microphone Access Error", detailedErrorInfo);

}